import React from "react";
import "./Aboutus.css";
import coffee from "../images/COFFEE 1.png";
import desktop from "../images/desktop pic.png";
import pics from "../images/pics.png";

function Aboutus() {
  return (
    <div>
      <section className="aboutus-wrapper">
        <div className="about-us top">
          <div className="left-top left">
            <p className="title">Abol is the first cup of coffee</p>
            <div className="description">
              <p>
                Abol is an education technology (edtech) company with <br />
                a mission to leverage technology to address the issue of <br />
                inadequate education quality in Ethiopia. We aim to <br />
                be the leading educational platform in Africa.
              </p>
            </div>
          </div>
          <div className="right right-top">
            <img src={coffee} alt="coffee cup image" />
          </div>
        </div>
        <div className="about-us middle">
          <div className="left">
            <p className="title">
              You don’t have to say put the phone <br />
              down and study. Kids can learn <br />
              on the phone.
            </p>
            <div className="description">
              <p>
                Our range of products is diverse, providing our customers with
                comprehensive solutions to meet their needs.
              </p>
            </div>
          </div>
          <div className="right">
            <img src={desktop} alt="desktop picture" />
          </div>
        </div>
        <div className="about-us bottom">
          <div className="left">
            <p className="title">
              There are 26 million students in Ethiopia & 33 Million use
              internet
            </p>
            <div className="description">
              <p>Changing the way of learning one student at a time.</p>
            </div>
          </div>
          <div className="right">
            <img src={pics} alt="" />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Aboutus;
