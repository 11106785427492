import React from "react";
import "./Navbar.css";
import logo from "../images/logo.png";
import { NavLink } from "react-router-dom";

function Navbar() {
  return (
    <div>
      {/* <ul>
        <li>
          <NavLink to="/">home</NavLink>{" "}
        </li>
        <li>
          <NavLink to="/contact">contact</NavLink>{" "}
        </li>
      </ul> */}
      <nav>
        <div className="logo-wrapper">
          <NavLink to="/">
            <img src={logo} alt="logo" />
          </NavLink>
        </div>
        <div className="link-wrapper">
          <ul>
            <li>
              <NavLink to="/" activeClassName="activeItem">
                HOME
              </NavLink>
            </li>
            <li>
              <NavLink to="/products" activeClassName="activeItem">
                PRODUCTS
              </NavLink>
            </li>
            <li>
              <NavLink to="/aboutus" activeClassName="activeItem">
                ABOUT US
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact" activeClassName="activeItem">
                CONTACT
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/account">ACCOUNT</NavLink>
            </li> */}
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
