import React from "react";
import Icon from "../images/sign-in-icon.png";
import "./Account.css";

function Account() {
  return (
    <div>
      <section>
        <div className="sign-in-wrapper">
          <div className="sign-in-icon">
            <img src={Icon} alt="sign-in-icon" />
          </div>
          <div className="sign-in-form">
            <input className="email" type="email" placeholder="Email" />
            <input
              className="password"
              type="password"
              placeholder="Password"
            />
          </div>
          <div className="sign-in-button">
            <button>Log in</button>
          </div>
          <div className="sign-in-txt">
            <p>
              Already Have An Account? <a href="">Sign In</a>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Account;
