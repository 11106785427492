import React from "react";
import HomePage from "../images/home-page.png";
import "./Home.css";
import { NavLink } from "react-router-dom";

function Home() {
  return (
    <div>
      <section className="home-wrapper">
        <div className="home-title">
          <p>We create that ‘AHA’ moment</p>
          <p>through E-learning</p>
        </div>
        <div className="home-description">
          <p>Abol is an education technology (Edtech) company with a</p>
          <p>mission to leverage technology to address the issue</p>
          <p>of inadequate education quality in Ethiopia.</p>
        </div>
        <div className="home-image">
          <img src={HomePage} alt="" />
        </div>
      </section>
    </div>
  );
}

export default Home;
