import React from "react";
import "./Products.css";
import GamePic from "../images/game-pic.png";

function Products() {
  return (
    <div>
      <section>
        <div className="our-product-wrapper">
          <p className="our-products">Our Products</p>
          <p className="discription">
            Our range of products is diverse, providing our students with
          </p>
          <p className="discription">
            comprehensive solutions to meet their needs.
          </p>
          <p className="simple">Simple | Fun | Engaging</p>
        </div>
        <div className="products">
          <div className="left-product left-product-type">
            <div className="product-image">
              <img src={GamePic} alt="" />
            </div>
            <div className="product-title">
              <p>Educational Games</p>
            </div>
            <div className="product-description">
              <p>You’ll find diverse games that help</p>
              <p>kids to thrive in science and other</p>
              <p>subjects matterials</p>
            </div>
          </div>
          <div className="right-product right-product-type">
            <div className="product-image">
              <img src={GamePic} alt="" />
            </div>
            <div className="product-title">
              <p>Applications</p>
            </div>
            <div className="product-description">
              <p>You’ll find diverse games that help</p>
              <p>kids to thrive in science and other</p>
              <p>subjects matterials</p>
            </div>
          </div>
        </div>
        <div className="products bottom-products">
          <div className="left-product left-product-type">
            <div className="product-image">
              <img src={GamePic} alt="" />
            </div>
            <div className="product-title">
              <p>Animated Videos</p>
            </div>
            <div className="product-description">
              <p>You’ll find diverse games that help</p>
              <p>kids to thrive in science and other</p>
              <p>subjects matterials</p>
            </div>
          </div>
          <div className="right-product right-product-type">
            <div className="product-image">
              <img src={GamePic} alt="" />
            </div>
            <div className="product-title">
              <p>Others</p>
            </div>
            <div className="product-description">
              <p>You’ll find diverse games that help</p>
              <p>kids to thrive in science and other</p>
              <p>subjects matterials</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Products;
