import "./App.css";
import Home from "./Components/Home";
import { Route, Routes } from "react-router-dom";
import Contact from "./Components/Contact";
import Navbar from "./Components/Navbar";
import Products from "./Components/Products";
import Aboutus from "./Components/Aboutus";
import Account from "./Components/Account";
import { Link, animateScroll as scroll } from "react-scroll";
import "./Components/Navbar.css";
import logo from "./images/logo.png";
import { NavLink } from "react-router-dom";

function App() {
  return (
    <div>
      <div>
        <nav style={{ position: "fixed" }}>
          <div className="logo-wrapper">
            <Link to="home" activeClass="active">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className="link-wrapper">
            <ul>
              <li>
                <Link to="home" activeClassName="activeItem">
                  HOME
                </Link>
              </li>
              <li>
                <Link to="products" activeClass="active">
                  PRODUCTS
                </Link>
              </li>
              <li>
                <Link to="aboutus" activeClass="active">
                  ABOUT US
                </Link>
              </li>
              <li>
                <Link to="contact" activeClass="active">
                  CONTACT
                </Link>
              </li>
              {/* <li>
              <NavLink to="/account">ACCOUNT</NavLink>
            </li> */}
            </ul>
          </div>
        </nav>
        {/* <Navbar /> */}
      </div>
      <div id="home">
        <Home />
      </div>
      <div id="products">
        <Products />
      </div>
      <div id="aboutus">
        <Aboutus />
      </div>
      <div id="contact">
        <Contact />
      </div>

      {/* <div>break</div>
      <div>break</div>

     
      <div>break</div>

      
      <div>break</div>

      
      <div>break</div>

     
      <div>break</div>

      <Account /> */}
      {/* <Routes>
        <Route path="/" element={<Home />} />
        <Route path="products" element={<Products />} />
        <Route path="aboutus" element={<Aboutus />} />
        <Route path="contact" element={<Contact />} />
        <Route path="account" element={<Account />} />
      </Routes> */}
    </div>
  );
}

export default App;
