import React from "react";
import "./Contact.css";

function Contact() {
  return (
    <div>
      <section class="contact-wrapper">
        <div class="contact-us">
          <p>Contact us</p>
        </div>
        <div class="contact-us-description">
          <p>Create an account to enjoy all the services</p>
          <p>without any ads for free!</p>
        </div>
        <form action="">
          <input class="input Name" type="text" placeholder="Name" />
          <br />
          <input class="input Email" type="email" placeholder="Email" />
          <br />
          <textarea
            name="comment"
            form="usrform"
            placeholder="Message"
          ></textarea>
          <button class="submit">Send</button>
        </form>
        <div class="sign-in-txt">
          <p>
            Already Have An Account? <a href="">Sign In</a>
          </p>
        </div>
      </section>
    </div>
  );
}

export default Contact;
